export const definedPermissions = {
  ViewUsers: "ViewUsers",
  AddUser: "AddUser",
  EditUser: "EditUser",
  DeleteUser: "DeleteUser",
  ViewRoles: "ViewRoles",
  AddRole: "AddRole",
  EditRole: "EditRole",
  DeleteRole: "DeleteRole",
  ViewCustomers: "ViewCustomers",
  EditCustomer: "EditCustomer",
  DeleteCustomer: "DeleteCustomer",
  ViewOrders: "ViewOrders",
  ViewCatererDetails: "ViewCatererDetails",
  ViewAirportDropdown: "ViewAirportDropdown",
  ViewIntegrationDashboard: "ViewIntegrationDashboard"
};
