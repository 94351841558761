import React, { useEffect } from "react";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/store";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import mockServer from "./mock";
import { persistor } from "./redux/store/index";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV;

if (environment !== "production") {
  mockServer({ environment });
}

function App() {
  if (environment === "production") {
    ReactGA.initialize("G-9Q1LYM35VD");
  }

  useEffect(() => {
    if (environment === "production")
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <LocalizationProvider  dateAdapter={AdapterDayjs}>
              <Layouts props />
            </LocalizationProvider>
          </ThemeSwitcherProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
